<script setup lang="ts">
import { Modal } from 'jenesius-vue-modal';
import { DialogClose, DialogContent, DialogOverlay, DialogPortal, DialogRoot } from 'radix-vue';

import Tracker from '~/libs/Tracker';

import type { Product } from '~/services/main';
import { useUserProfileStore } from '~/services/profile';

import { IconCircleExclamationFilled, cn } from '~/components/5x';
import IconX from '~/components/5x/icons/IconX.vue';
import Button from '~/components/5x/ui/button/Button.vue';

export type WeolbuStudyLinkModalProps = {
  product: { displayName: Product['displayName']; displaySeq: Product['displaySeq'] };
};
export type WeolbuStudyLinkModalReturns = boolean;

const props = defineProps<WeolbuStudyLinkModalProps>();
const emit = defineEmits<{ (e: typeof Modal.EVENT_PROMPT, payload: WeolbuStudyLinkModalReturns): void }>();

const handleVisible = computed<boolean>({
  get: () => true,
  set: (val) => !val && nextTick(() => emit(Modal.EVENT_PROMPT, false)),
});

const profileStore = useUserProfileStore();

watch(
  handleVisible,
  () => {
    if (handleVisible.value) {
      Tracker['View Popup']({
        displayName: props.product?.displayName,
        displaySeq: String(props.product?.displaySeq ?? 'none'),
        popupId: 'weolbu-study-link',
        popupName: '월부스-추가혜택',
      });
    }
  },
  { immediate: true, once: true },
);

const handleClick = (type: 'yes' | 'no') => {
  switch (type) {
    case 'yes':
      Tracker['Click Button']('weolbu-study-link', '추가혜택', {
        displayName: props.product?.displayName,
        displaySeq: String(props.product?.displaySeq ?? 'none'),
      });

      emit(Modal.EVENT_PROMPT, true);
      break;
    case 'no':
      Tracker['Click Button']('weolbu-study-link', '닫기', {
        displayName: props.product?.displayName,
        displaySeq: String(props.product?.displaySeq ?? 'none'),
      });

      emit(Modal.EVENT_PROMPT, false);
      break;
  }
};
</script>

<template>
  <DialogRoot :open="handleVisible">
    <DialogPortal>
      <DialogOverlay
        class="fixed inset-0 bg-black/70 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 flex justify-center items-center">
        <DialogContent
          :class="
            cn(
              'relative overflow-hidden',
              'bg-white w-full md:w-[400px] h-full md:h-[478px] md:rounded-2xl',
              ' px-4 md:px-7',
              'flex w-full items-center',
            )
          ">
          <div class="w-full h-full flex flex-col justify-center items-center pb-[80px] md:p-0">
            <img class="block w-[200px] h-40 mx-auto" src="/images/weolbu-study-link.webp" />

            <div class="h-10 md:h-8" />

            <p class="text-center font-bold text-2xl/[30px] md:text-3xl/[34px] text-neutral-1000">
              {{ profileStore.profile?.nickName || profileStore.profile?.userName }}님,<br />
              미국주식 추가혜택이<br />
              도착했어요!
            </p>

            <div class="h-5" />

            <Button class="block w-full mx-auto max-w-[343px]" size="xl" variant="solid" @click="handleClick('yes')">
              바로 확인하기
            </Button>

            <div class="h-2" />

            <p class="text-neutral-700 text-sm/4 font-semibold flex items-center justify-center space-x-1 h-7">
              <IconCircleExclamationFilled size="18" class="text-red-500" />
              <span> 이 화면을 닫으면 혜택이 사라져요! </span>
            </p>
          </div>

          <div class="absolute top-4 md:right-4 hover:cursor-pointer" @click="handleClick('no')">
            <DialogClose as-child>
              <IconX size="24" class="text-black md:text-neutral-500" />
            </DialogClose>
          </div>
        </DialogContent>
      </DialogOverlay>
    </DialogPortal>
  </DialogRoot>
</template>

<style scoped></style>
