<!-- eslint-disable no-useless-escape -->
<script setup lang="ts">
import { useBenepiaStore } from '~/services/benepia';

import { isMobileDevice } from '~/utils/browserUtil';

const DEFAULT_SITE_NAME = 'newfront';

const isBenepiaActive = ref(false);
const iframeContent = ref('');

const benepiaStore = useBenepiaStore();

onMounted(async () => {
  await benepiaStore.init();

  const { authorization, authorizationCode } = benepiaStore.benepia;
  if (!authorizationCode) {
    return;
  }

  isBenepiaActive.value = true;

  // newfront는 웹 기본값이므로 베네피아에서 변경되지 않으면 기본값으로 사용 가능
  const domain = `${authorization?.siteName || DEFAULT_SITE_NAME}.benepia.co.kr`;

  // 포인트 조회 링크 숨김 조건 여부
  const shouldHidePointLink = !authorization?.siteName || isMobileDevice();

  // iframe HTML 템플릿 생성
  iframeContent.value = `
    <!DOCTYPE html>
    <html lang="en">
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <meta http-equiv="X-UA-Compatible" content="ie=edge">
      <title>Benepia</title>
      <style>
        html, body {
          margin: 0;
          padding: 0;
        }
        ${shouldHidePointLink ? 'a { display: none !important; }' : ''}
      </style>
    </head>
    <body>
      <script>
        (function loadBenepiaScript() {
          const href = "https://${domain}/beneCommHeaderUi.jsp?benepiaDomain=${domain}";
          document.write('<script type="text/javascript" src="' + href + '"><\\/script>');
        })();

        // 새 창으로 링크 열기 설정
        window.onload = function() {
          const links = document.querySelectorAll('a');
          links.forEach(link => {
            link.setAttribute('target', '_blank');
          });
        };
      <\/script>
    </body>
    </html>
  `;
});
</script>

<template>
  <div>
    <!-- 베네피아 로그인 테스트용 버튼 -->
    <!-- <form action="/authorization/benepia" method="POST">
      <input
        type="hidden"
        name="encParam"
        value="05qXxEX2H9tgQzFe%2BlGYiLTc4u4OQqwWLGOU7ip507vOzELx37lL7uyzy4TgmxqCCgEtJM9d9L9K1llrHUZSjnzIjCSIjIkn5S2L752IhYBLCMXxKYOLD0Bm7oKruT1Uh6baEdYR%2FikK%2B0pjBwPHNQ%3D%3D" />
      <button type="submit">베네피아 (POST)</button>
    </form>
    <button
      @click="
        () =>
          navigateTo(
            `/authorization/benepia?encParam=05qXxEX2H9tgQzFe%2BlGYiLTc4u4OQqwWLGOU7ip507vOzELx37lL7uyzy4TgmxqCCgEtJM9d9L9K1llrHUZSjnzIjCSIjIkn5S2L752IhYBLCMXxKYOLD0Bm7oKruT1Uh6baEdYR%2FikK%2B0pjBwPHNQ%3D%3D`,
          )
      ">
      베네피아 (GET)
    </button> -->
    <div v-if="isBenepiaActive && iframeContent" class="h-[62px]">
      <!-- 베네피아 동적 iframe -->
      <iframe class="w-full" frameborder="0" scrolling="no" :srcdoc="iframeContent"> </iframe>
    </div>
  </div>
</template>
