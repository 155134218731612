import { promptModal } from 'jenesius-vue-modal';

import WeolbuStudyLinkModal from '~/services/payment/component/WeolbuStudyLinkModal/WeolbuStudyLinkModal.vue';
import type {
  WeolbuStudyLinkModalProps,
  WeolbuStudyLinkModalReturns,
} from '~/services/payment/component/WeolbuStudyLinkModal/WeolbuStudyLinkModal.vue';

export const openWeolbuStudyLinkModal = (props: WeolbuStudyLinkModalProps): Promise<WeolbuStudyLinkModalReturns> => {
  return new Promise((resolve) => {
    if (!$isClient()) {
      return;
    }

    nextTick(async () => {
      resolve((await promptModal(WeolbuStudyLinkModal, { ...props })) as Promise<WeolbuStudyLinkModalReturns>);
    });
  });
};
